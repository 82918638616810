import type { Banner } from "types/types"

export const useAppStore = defineStore("app", {
    state: () => ({
        /**
         * Controls the modal that takes over the whole app for maintenance mode.
         */
        showMaintenanceModal: false,

        showingBanner: false,

        banner: null as Banner | null,
    }),

    actions: {
        showBanner(banner: Banner) {
            nextTick(() => {
                this.showingBanner = true
                this.banner = banner
            })
        },
        hideBanner() {
            this.showingBanner = false
            this.banner = null
        },
    },
})
